import { useEffect, useState } from "react";
import React from "react";

type ChildProps = {
  name: string,
  id: number,
  bio?: string,
}

const Child = (props: ChildProps) => {
  const getDate = () => new Date().toLocaleString();
  const getTextColour = () => { return numberOne < 20 ? {} : { color: "red" } };

  const [intervalSetting, setIntervalSetting] = useState(1);
  const [numberOne, setClickA] = useState(0);
  const [clickAa, setClickAa] = useState(getDate());
  const [numberTwo, setClickB] = useState(0);


  useEffect(() => {
    if (numberOne === 0) {
      console.log('Component loaded!')
    } else {
      //console.log('Button A was clicked!');
    }
  }, [numberOne]);

  useEffect(() => {
    const interval = setInterval(
      () => {
        if (intervalSetting === 1) {
          setIntervalSetting(60000);
        }
        let newNumber = 0;
        if (numberOne < 20) {
          newNumber = Math.floor(Math.random() * 20 + 1)
          setClickA(newNumber);
          setClickAa(getDate());
        }
       if (newNumber === 20 && numberTwo < 1) {
          setClickB(Math.floor(Math.random() * 4 + 1));
        }
      },
      intervalSetting
    );

    // clean up interval on unmount
    return () => {
      clearInterval(interval);
    };
  }, [numberOne, numberTwo, intervalSetting]);

  return (
    <div>
      <p>{clickAa}</p>
      <p style={getTextColour()}>Random Number (1-20): {numberOne}</p>
      <p>Generated Number (1-4): {numberTwo}</p>

      <p style={{ fontSize: "small" }}><button onClick={() => setIntervalSetting(intervalSetting + 5000)}>Slower</button> Interval: {intervalSetting} <button onClick={() => setIntervalSetting(intervalSetting - 5000)}>Faster</button></p>

      <p id="click-a"></p>
    </div>
  )
};

export default Child